<template>
  <div class="transfer-page"></div>
</template>

<script>
export default {
  name: "transfer",
  data() {
    return {};
  },
  async created() {
    const { code } = this.$route.query;
    if (code) {
      // 调用登录接口,成功后跳转首页
      const { data } = await this.$api.getLogin({ type: 3, code });
      console.log(data);
      if (data.code == 1) {
        localStorage.setItem("user_info", JSON.stringify(data.data.userinfo));
        localStorage.setItem("user_id", data.data.userinfo.id);
        this.$router.push({ name: "home" });
      } else {
        this.$router.push({ name: "login", params: { type: "login" } });
      }
    }
  },
  async mounted() {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.transfer-page {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
</style>
